import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
	// url = 'https://ottoschmidt20.host-me.net/api/';
	url = 'https://os20.mmc-entwicklung.de/api/';
	api_token = "";
	user_data = {};
  loading: any;
  toast: any;
  alert: any;
  finished_loading = false;
  current_chat_id = 0;
  conference: any;
  hide_chat_bubble = false;
  
	constructor(
		public router: Router,
		public loadingController: LoadingController, 
		private http: HttpClient,
		public toastController: ToastController, 
		public alertController: AlertController
	) {
		this.getData();
	}

	async getData() {
		if(!this.api_token) {
			const api_key_object = await Preferences.get({ key: 'api_token' });
			const user_data_object = await Preferences.get({ key: 'user_data' });
			console.log("Constructor of Events Service API: ");
	    this.api_token = api_key_object.value;
	    if(user_data_object.value) {
	    	this.user_data = JSON.parse(user_data_object.value);
	    }
	    console.log(this.user_data);
		}
	}

	getConferences(): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "conferences?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	postConferenceAccess(access_code): Observable<any> {
		this.getData();
		let postData = {
			'access_code' : access_code,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "conference_access", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	postShowInList(conference_id, show): Observable<any> {
		this.getData();
		let postData = {
			'show' : show,
			'conference' : conference_id,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "member/show_in_list", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	getPastConferences(): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "conferences?past=true&api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getConferenceDetails(id): Observable<any> {
		this.getData();
		var self = this;

		return this.http.get(
			this.url + "conference/" + id + "?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				self.conference = results;
				return results;
			})
		);
	}

	getPostDetails(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "newsticker/" + id
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getOwnProfile(): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "member/profile?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}
 
	postOwnProfile(profile, basePhoto): Observable<any> {
		this.getData();
		let postData = {
			'title' : profile.title,
			'salutation' : profile.salutation,
			'firstName' : profile.firstName,
			'lastName' : profile.lastName,
			'email' : profile.email,
			'city' : profile.city,
			'zipCode' : profile.zipCode,
			'street' : profile.street,
			'employer' : profile.employer,
			'jobTitle' : profile.jobTitle,
			'phoneNumber' : profile.phoneNumber,
			'privacy_consent' : profile.privacy_consent,
			'basePhoto': basePhoto,
			'_method' : 'put',
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "member/profile", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	getLocationDetails(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "location/" + id
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getConferenceInvitees(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "conference/" + id + "/members?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getInviteeDetails(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "members/" + id + '?api_token=' + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getTrack(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "track/" + id
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getTracks(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "tracks/" + id
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getActivity(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "activity/" + id
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getNotes(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "activity/" + id + "/notes?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	postNote(id, message): Observable<any> {
		this.getData();
		let postData = {
			'message' : message,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "activity/" + id + "/note", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	getComments(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "activity/" + id + "/comments?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	postComment(id, message): Observable<any> {
		this.getData();
		let postData = {
			'message' : message,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "activity/" + id + "/comment", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	getPolls(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "activity/" + id + "/polls?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	postVote(poll_id, option_id): Observable<any> {
		this.getData();
		let postData = {
			'option' : option_id,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "poll/" + poll_id + "/vote", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	postWebinarRegistration(webinar_id): Observable<any> {
		this.getData();
		let postData = {
			'webinar' : webinar_id,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "webinar/" + webinar_id + "/register", postData)
		.pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	} 

	getSponsor(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "sponsor/" + id
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}	

	getWebinar(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "webinar/" + id + "?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getSpeaker(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "speaker/" + id + "?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}	

	getSpeakers(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "conference/" + id + "/speakers" + "?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}	

	// Chat
	getTickets(id): Observable<any> {
		this.getData();
		return this.http.get(
			this.url + "chats/" + id + "/support?api_token=" + this.api_token
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	openTicket(id): Observable<any> {
		this.getData();
		
		let t = new Date();
		let now = ('0' + t.getDate()).slice(-2) + "." +  ('0' + (t.getMonth()+1)).slice(-2) + "." + t.getFullYear() + " " + t.getHours() + ":" + ('0' + t.getMinutes()).slice(-2) + ":" + ('0' + t.getSeconds()).slice(-2);
	
		let postData = {
			'conference_id' : id,
			'api_token' : this.api_token,
			'text' : "Neue Support Anfrage - Gestartet " + now + "Uhr"
		};

		return this.http.post(
			this.url + "conference/" + id + "/chat/support/create", postData
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	getMessages(chat_id, refresh): Observable<any> {
		this.getData();
		
		let since = "2022-04-20 11:00:00";
		let postData = {
			'since' : since,
			'api_token' : this.api_token,
			'exclude_own' : false
		};

		if(refresh) {
			// Get current timestamp
			let t = new Date();
			t.setSeconds(t.getSeconds() - 10);

			since = t.getFullYear() + "-" + ('0' + (t.getMonth()+1)).slice(-2) + "-" + ('0' + t.getDate()).slice(-2) + " " + ('0' + t.getHours()).slice(-2) + ":" + ('0' + t.getMinutes()).slice(-2) + ":" + ('0' + t.getSeconds()).slice(-2);
			console.log(since);

			postData = {
				'since' : since,
				'api_token' : this.api_token,
				'exclude_own' : true
			};
		} 

		return this.http.post(
			this.url + "chat/" + chat_id + "/messages", postData
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	postMessage(chat_id, message): Observable<any> {
		this.getData();
		let postData = {
			'text' : message,
			'api_token' : this.api_token
		};
		console.log(postData);
		return this.http.post(
			this.url + "chat/" + chat_id + "/message/store", postData
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	// Chatmoderation

	deleteMessage(message_id): Observable<any> {
		this.getData();

		let postData = {
			'message_id' : message_id,
			'api_token' : this.api_token
		};

		return this.http.post(
			this.url + "message/delete", postData
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}

	deactivateUser(message_id): Observable<any> {
		this.getData();

		let postData = {
			'message_id' : message_id,
			'api_token' : this.api_token
		};

		return this.http.post(
			this.url + "message/deactivate-user", postData
		).pipe(
			map(results => {
				console.log('RAW: ', results);
				return results;
			})
		);
	}


	// Helper functions
  toChat(id) {
  	this.router.navigate(['/conversation/' + id]);
  }

	logout() {
    this.api_token = "";
    (window as any).user_data = false;
    Preferences.set({ key : 'api_token', value : ""});
    Preferences.set({ key : 'user_data', value : ""});
    this.showToast("Ihr Sicherheitstoken ist ungültig. Sie wurden ausgeloggt.");
    this.router.navigate(['/login']);  		
	}

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: 'Lade Daten...',
      spinner: 'circles'
    });

    this.loading.present();
  }

  stopLoading() {
    this.finished_loading = true;
    this.loadingController.dismiss();
  }

  showToast(message) {
      this.toast = this.toastController.create({
        color: 'primary',
        message: message,
        duration: 4000
      }).then((toastData)=>{
          toastData.present();
      });
  }
    
  hideToast() {
      this.toast = this.toastController.dismiss();
  }	
}
