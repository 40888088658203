import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title : "Home",
      url   : "/conferences",
      icon  : "home"
    },
    {
      title : "Mein Profil",
      url   : "/profile",
      icon  : "person-circle" 
    },
    {
      title : "Impressum",
      url   : "/imprint",
      icon  : "document-text"
    },
    {
      title : "Datenschutz",
      url   : "/privacy",
      icon  : "body"
    },
    {
      title : "Logout",
      url   : "/logout",
      icon  : "key"
    }
  ];

  constructor(public platform: Platform, public api: EventsService) {}

  initializeApp() {
    console.log("** App Initialized ** ");
    this.platform.ready().then(() => {
      const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
      };

      console.log("** Plattform Ready ** ");
    });
  }
}
